import { createAction, props } from "@ngrx/store";
import { Menu } from "../../interfaces/menu.interface";

export enum LayoutActionsTypes {
  getMenu = "[Layout] Get menu",
  successGetMenu = "[Layout] Success get menu",
  errorGetMenu = "[Layout] Error Get Menu",

  getProfileUrl = "[Layout] Get Profile Image URL",
  successGetProfileUrl = "[Layout] Get Profile Image URL Success",
  errorGetProfileUrl = "[Layout] Get Profile Image URL Failure",
}

export const getMenu = createAction(LayoutActionsTypes.getMenu);
export const successGetMenu = createAction(
  LayoutActionsTypes.successGetMenu,
  props<{ menu: Menu }>()
);
export const errorGetMenu = createAction(
  LayoutActionsTypes.errorGetMenu,
  props<{ payload: any }>()
);

// URL PROFILE
export const getProfileImageUrl = createAction(
  LayoutActionsTypes.getProfileUrl,
  props<{ userId: string }>()
);
export const successGetProfileUrl = createAction(
  LayoutActionsTypes.successGetProfileUrl,
  props<{ imageUrl: string }>()
);
export const errorGetProfileUrl = createAction(
  LayoutActionsTypes.errorGetProfileUrl,
  props<{ error: string }>()
);
